<template>
  <div class="dialog-add-account" v-loading="loading">
    <div class="dialog-add-account__title" v-html="title">
    </div>
    <div v-if="!isEditable" class="dialog-add-account__desc">
      {{ $t('referral_data.info') }}
    </div>
    <div v-if="!isEditable" class="dialog-add-account__desc">
      {{ $t('referral_data.conditions') }}<a href="/program-description" class="outside-link" target="_blank">{{ $t('referral_data.desc_link') }}</a>
    </div>
    <form class="dialog-add-account__form">
      <div class="dialog-add-account__form-title">
        {{ $t('referral_data.requisites') }}
      </div>
      <div class="dialog-add-account__form-block">
        <div class="dialog-add-account__form-row">
          <div class="dialog-add-account__form-col">
            <div class="dialog-add-account__form-item">
              <div class="form-input"
                :class="[{ 'form-input--full': form.inn }, { 'form-input--error': errors.inn || (authErrors && authErrors.inn) }]">
                <input v-model="form.inn" type="text" required :disabled="isEditable">
                <span class="form-input__label"> {{ $t('business_account_data.inn') }} </span>
                <div v-if="errors.inn" class="form-input__error" v-text="errors.inn" />
                <div v-if="authErrors && authErrors.inn" class="form-input__error">
                  <p v-for="(err, index) in authErrors.inn" :key="index">
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isCorrectInn" class="dialog-add-account__form-col">
            <ButtonElement :text="$t('button_props.check_tin')"
              @click-button="checkInn" />
          </div>
          <div v-if="isCorrectInn" class="dialog-add-account__form-col">
            <div class="dialog-add-account__form-item">
              <div class="form-input"
                :class="[{ 'form-input--full': form.fio }, { 'form-input--error': errors.fio || (authErrors && authErrors.fio) }]">
                <input v-model="form.fio" type="text" required :disabled="isEditable">
                <span class="form-input__label">{{ $t('business_account_data.full_name') }}</span>
                <div v-if="errors.fio" class="form-input__error" v-text="errors.fio" />
                <div v-if="authErrors && authErrors.fio" class="form-input__error">
                  <p v-for="(err, index) in authErrors.fio" :key="index">
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="isCorrectInn">
        <div class="dialog-add-account__form-title">
          <!-- Bank -->
          {{ $t('business_account_data.bank') }}
        </div>
        <div class="dialog-add-account__form-block">
          <div class="dialog-add-account__form-row">
            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input"
                  :class="[{ 'form-input--full': form.bank_name }, { 'form-input--error': errors.bank_name || (authErrors && authErrors.bank_name) }]">
                  <input v-model="form.bank_name" type="text">
                  <span class="form-input__label">{{ $t('business_account_data.bank_name') }}</span>
                  <div v-if="errors.bank_name" class="form-input__error" v-text="errors.bank_name" />
                  <div v-if="authErrors && authErrors.bank_name" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bank_name" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="locale === 'RU'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input"
                  :class="[{ 'form-input--full': form.bic }, { 'form-input--error': errors.bic || (authErrors && authErrors.bic) }]">
                  <input v-model="form.bic" type="text">
                  <span class="form-input__label">{{ $t('business_account_data.bic') }}</span>
                  <div v-if="errors.bic" class="form-input__error" v-text="errors.bic" />
                  <div v-if="authErrors && authErrors.bic" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bic" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="locale === 'RU'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input"
                  :class="[{ 'form-input--full': form.iban }, { 'form-input--error': errors.iban || (authErrors && authErrors.iban) }]">
                  <input v-model="form.iban" type="text">
                  <span class="form-input__label">{{ $t('business_account_data.account') }}</span>
                  <div v-if="errors.iban" class="form-input__error" v-text="errors.iban" />
                  <div v-if="authErrors && authErrors.iban" class="form-input__error">
                    <p v-for="(err, index) in authErrors.iban" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input"
                  :class="[{ 'form-input--full': form.bank_address }, { 'form-input--error': errors.bank_address || (authErrors && authErrors.bank_address) }]">
                  <input v-model="form.bank_address" type="text">
                  <span class="form-input__label">{{ $t('business_account_data.bank_address') }}</span>
                  <div v-if="errors.bank_address" class="form-input__error" v-text="errors.bank_address" />
                  <div v-if="authErrors && authErrors.bank_address" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bank_address" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isEditable" class="dialog-add-account__form-agree">
          <label class="input__item-checkbox">
            <input type="checkbox" value="'all'" required v-model="form.offer_agreement_accepted">
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('business_account_data.agree_with') }}
                <a href="https://profitads.ru/dogovor-oferty-ref/" target="_blank">{{$t('business_account_data.agent_offer_contract') }}</a>
              </span>
            </span>
          </label>
          <div v-if="errors.offer_agreement_accepted" class="form-input__error"
            v-text="errors.offer_agreement_accepted" />
        </div>
        <div class="dialog-add-account__form-buttons">
          <ButtonElement :text="$t('button_props.save')" @click-button="saveData" />
          <ButtonElement :text="$t('button_props.cancel')" view="empty" @click-button="closeDialog" />
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import formValidate from '../../mixin/formValidate'
import ButtonElement from '../../components/elements/ButtonElement'
import axios from 'axios'

export default {
  name: 'ReferralData',
  components: {
    ButtonElement
  },
  mixins: [formValidate],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      loading: false,
      isCorrectInn: false,//change before commit 
      form: {
        inn: '',
        bic: '',
        iban: '',
        bank_address: '',
        bank_name: '',
        offer_agreement_accepted: '',
        fio: ''
      },
      errors: {
        inn: '',
        bank_name: '',
        agree_terms_contract: '',
        bic: '',
        bank_address: '',
        offer_agreement_accepted: '',
        fio: ''
      },
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    user() {
      return this.$store.state.Auth.user
    },
    role() {
      return this.$store.state.Auth.role
    },
    innField() {
      return this.form.inn
    },
    editAgent() {
      return this.$store.state.Referrals.editAgent
    },
    isEditable() {
      return this.$store.state.Dialog.isEditable
    },
    title(){
      return this.isEditable ? this.$t('referral_data.title_edit') : this.$t('referral_data.title')
    },

  },
  watch: {//uncomment before merge
    innField(v1, v2) {
      if (v1 && v2) this.isCorrectInn = false 
    }
  },
  mounted() {
    this.isCorrectInn = this.isEditable ? true : false
    this.form.bank_name = this.editAgent ? this.editAgent.bank_name : ''
    this.form.bic = this.editAgent ? this.editAgent.bic : ''
    this.form.iban = this.editAgent ? this.editAgent.iban : ''
    this.form.bank_address = this.editAgent ? this.editAgent.bank_address : ''
    this.form.inn = this.editAgent ? this.editAgent.inn : ''
    this.form.fio = this.editAgent ? this.editAgent.fio : ''
  },
  methods: {
    clearForm() {
      this.inn = '',
        this.form.bic = '',
        this.form.iban = '',
        this.form.bank_address = '',
        this.form.bank_name = '',
        this.form.offer_agreement_accepted = false,
        this.form.fio = ''
    },
    saveData() {
      if (this.isEditable) {
        this.editAccount()
      } else {
        this.addNewAccount()
      }
    },
    editAccount() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      this.loading = true
      const data = {}
      const clientToken = localStorage.getItem('client_token')

      data.bic = this.form.bic
      data.iban = this.form.iban
      data.bank_name = this.form.bank_name
      data.bank_address = this.form.bank_address
      data.id = this.user.agent.id

      this.$store.dispatch('Referrals/editReferral', { data, token: clientToken })
        .then(() => {
          this.loading = false
          this.dialogVisible = false
          this.$notify({
            message: this.$t('referral_data.referral_updated'),
            type: 'success'
          });
          this.clearForm()
          axios({ url: process.env.VUE_APP_API_URL + '/auth/userinfo', method: 'GET', headers: { 'Authorization': `Bearer ${clientToken}`} })
          .then(resp => {
            this.$store.commit('Auth/setData', { label: 'user', data: resp.data.data }) 
            this.closeDialog()
          })
        })
        .catch((error) => {
          this.loading = false
          this.$notify({
            message: this.$t('business_account_data.error'),
            type: 'error'
          });
          console.error('error', error)
        })
    },
    addNewAccount() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      this.loading = true
      const data = {}
      const clientToken = localStorage.getItem('client_token')
      for (let key in this.form) {
        if (this.form[key]) {
          if (key === 'offer_agreement_accepted') {
            data.rules = this.form['offer_agreement_accepted']
          } else {
            data[key] = this.form[key]
          }
        }
      }
      this.$store.dispatch('Referrals/addReferral', { data, token: clientToken })
        .then(() => {
          this.loading = false
          this.dialogVisible = false
          this.$notify({
            message: this.$t('business_account_data.referral_created'),
            type: 'success'
          });
          this.clearForm()
          axios({ url: process.env.VUE_APP_API_URL + '/auth/userinfo', method: 'GET', headers: { 'Authorization': `Bearer ${clientToken}`} })
          .then(() => {
            localStorage.setItem('isOpenReferral', true)
            this.$router.push({ name: 'ReferralStatistics' })
            this.closeDialog()
            window.location.reload();
          })
          
        })
        .catch((error) => {
          this.loading = false
          this.$notify({
            message: this.$t('business_account_data.error'),
            type: 'error'
          });
          console.error('error', error)
        })
    },
    validateForm() {
      if (!this.form.offer_agreement_accepted && !this.isEditable) {
        this.errors.offer_agreement_accepted = this.$t('business_account_data.should_agree')
      }
      if (!this.form.inn) {
        this.errors.inn = this.$t('business_account_data.input_inn')
      }
      // if (!this.form.bank_name) {
      //   this.errors.bank_name = this.$t('business_account_data.input_bank')
      // }
      // if (!this.form.bic) {
      //   this.errors.bic = this.$t('business_account_data.input_bank_id')
      // }
      // if (!this.form.iban) {
      //   this.errors.iban = this.$t('business_account_data.input_account_number')
      // }
      // if (!this.form.bank_address) {
      //   this.errors.bank_address = this.$t('business_account_data.input_bank_address')
      // }
      if (!this.form.fio) {
        this.errors.fio = this.$t('business_account_data.input_full_name')
      }
    },
    closeDialog() {
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
    },
    checkInn() {
      this.loading = true
      const data = {
        inn: this.form.inn,
      }
      this.$store.dispatch('Ad/checkInnReferral', data)
        .then((data) => {
          this.isCorrectInn = true
          for (const key in data.data) {
            this.form[key] = data.data[key]
          }
          this.loading = false
        })
        .catch((err) => {
          this.isCorrectInn = false
          this.errors.inn = err.errors.inn ? err.errors.inn[0] : err.errors
          this.loading = false
        })
    },
    removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([k, v]) => [k, v != null && v !== "" ? v : 0])
      );
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.dialog-add-account {

  .course-percent {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .dialog-add-account__form-item {
      width: calc(50% - 1rem);
      height: unset;
    }
  }

  &__form-type--radio {
    margin-bottom: 1.5rem;
  }

  .button {
    display: inline-block;
  }

  &__form-type {
    label:first-of-type {
      border-right: 1px solid #BFC2C6;

      &.error {
        border-color: #EB5757;
      }
    }
  }
  .outside-link {
    color: var(--primary);
    text-decoration: underline;
  }
}
</style>